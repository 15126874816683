
import Vue from "vue";
import axios from "../../plugins/axios";
import VueI18n from "../../i18n";
import { AxiosResponse } from "axios";
import {
  Discrepancy,
  DiscrepanciesSummary,
} from "../../models/discrepanciessummary";

export default Vue.extend({
  name: "CountryOverviews",
  data: () => ({
    selectedCountry: null,
    countries: [],
    email: "",
    summary: [],
    rules: {
      required: (value: any) =>
        !!value || VueI18n.t("discrepanciessumary.validate.required"),
      email: (value: string) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return (
          pattern.test(value) || VueI18n.t("discrepanciessumary.validate.email")
        );
      },
    },
  }),
  created() {
    this.loadCountries();
  },
  watch: {
    async selectedCountry() {
      if (this.selectedCountry !== null) {
        const response: AxiosResponse<any> = await axios.get(
          "Discrepancy/Summary",
          {
            params: { countryId: this.selectedCountry },
          }
        );
        this.email = response.data.email;
        this.summary = response.data.discrepancies;
      }
    },
  },
  methods: {
    copyToClickBoard(lang: string) {
      let content = "";

      this.summary.forEach((element: any) => {
        let errorList = null;
        if (lang == "en") {
          errorList = element.discrepanciesEn;
        } else if (lang == "fr") {
          errorList = element.discrepanciesFr;
        } else if (lang == "es") {
          errorList = element.discrepanciesEs;
        }

        if (content.length > 0) content += `\n\n`;
        content += `${element.sectionName} - ${element.status} - ${element.errorCount}\n`;
        content += `----------------------------------------------\n`;
        if (element.comments != null)
          content +=
            this.$t("basic.comment") + ": " + element.comments + "\n\n";

        errorList.forEach((error: any) => {
          content += error + `\n`;
        });
      });
      navigator.clipboard
        .writeText(content)
        .then(() => {
          console.log("Text copied to clipboard...");
        })
        .catch((err) => {
          console.log("Something went wrong", err);
        });
    },
    async loadCountries() {
      this.countries = (await axios.get("Discrepancy/Countries")).data;
    },
    showCurrentCountryLocale(item: {
      name_es: any;
      name_fr: any;
      name_en: any;
    }) {
      switch (this.$i18n.locale) {
        case "es": {
          return item.name_es;
        }
        case "fr": {
          return item.name_fr;
        }
        default: {
          return item.name_en;
        }
      }
    },
    customFilter(
      item: { name_es: any; name_fr: any; name_en: any },
      queryText: string,
      itemText: any
    ) {
      const textOne = this.showCurrentCountryLocale(item).toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
  },
});
